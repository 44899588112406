<template>
  <v-sheet min-height="100%" :color="wsLIGHTCARD">
    <v-sheet max-width="1240" width="100%" class="mx-auto pa-6" :color="wsLIGHTCARD">
      <div>
        <h1  class="text-center mb-10">{{ $t('market.home.categories.title') }}</h1>
        <div class="d-flex justify-space-between overflow-auto mb-10">
          <v-hover #default="{hover}"   v-for="(item,i) in categories" :key="i">
            <ws-link :to="localeLink(`market/courses?category=${item.value}`)">
              <v-sheet

                  :color="!hover ? wsWHITE : `${wsWHITE}bb`"
                  height="300" width="260" min-width="260"
                  class="wsRoundedHalf d-flex flex-column mr-6 pointer"
                  style="transition: all 0.2s ease"

              >
                <ws-img
                    :src="item.img || '/library/img/market/other.png'"
                    :style="`scale: ${hover ? 1.1 : 1} `"
                    style="transition: scale 0.2s ease"
                >

                </ws-img>
                <div class="flex-grow-1"></div>
                <h4 class="text-center mb-6">{{  item.text  }}</h4>

              </v-sheet>
            </ws-link>

          </v-hover>

        </div>
      </div>
    </v-sheet>
  </v-sheet>



</template>

<script>
export default {
  name: "categoriesBlock",
  data() {
    return {
      categories : []
    }
  },
  async mounted() {
    let result = await this.$store.dispatch('courses/GET_SYSTEM_CATEGORIES')
    if (!result) {
      this.ERROR()
    }
    this.categories = result.items
  }
}
</script>

<style scoped>

</style>